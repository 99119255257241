import React, { useRef, useState } from "react";
import {
  Autocomplete,
  IconButton,
  Popper,
  TextField,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import SearchIcon from "@mui/icons-material/Search";

export function AutocompleteFilter({ options, placeholder, value, onChange }) {
  const [open, setOpen] = useState(false);
  const autocompleteRef = useRef(null); // Reference for the Autocomplete component

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, selectedOptions) => {
    // Map location_name back to location_id
    const selectedIds = selectedOptions.map((name) =>
      options.find((option) => option.location_name === name)?.location_id
    );
    onChange(selectedIds);
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      ref={autocompleteRef}
      options={options.map((option) => option.location_name)}
      value={value.map(
        (id) => options.find((option) => option.location_id === id)?.location_name
      )}
      onChange={handleChange}
      open={open}
      onOpen={handleToggle}
      onClose={handleClose}
      disableCloseOnSelect
      fullWidth
      PopperComponent={(props) => (
        <Popper
          {...props}
          anchorEl={autocompleteRef.current} // Anchor to the Autocomplete container
          placement="bottom-start"
          style={{
            width: autocompleteRef.current
              ? autocompleteRef.current.offsetWidth
              : undefined,
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.id}>
          <Checkbox checked={selected} sx={{ marginRight: 1 }} />
          {option}
        </li>
      )}
      renderTags={() => null} // Prevent rendering selected items as chips
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-root": {
              padding: ".5rem !important", // Adjust padding to your preference
            },
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <SearchIcon
                  sx={{
                    color: "rgba(0, 0, 0, 0.54)",
                    marginLeft: 1,
                    marginRight: 1,
                  }}
                />
                {params.InputProps.startAdornment}
              </>
            ),
            endAdornment: (
              <IconButton onClick={handleToggle}>
                {open ? <CloseIcon /> : <EditLocationAltIcon />}
              </IconButton>
            ),
          }}
        />
      )}
    />
  );
}
