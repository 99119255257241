// 1. React Imports:
import { useEffect, useState } from "react";

// 2. Third-Party Library Imports:
import { useLocation } from "react-router-dom";
import { Box, Drawer, Grid } from "@mui/material";

// 3. Component Imports:
import { LogDetails } from "../../components/logs/LogDetails";
import DataTable from "../../components/DataTable";

export function Log() {
  /*-------------------------------------
		State Variables and Hooks
	-------------------------------------*/
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [log, setLog] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search); // Parse the query string
  const aid = searchParams.get("aid"); // Get the value of 'aid'

  /*-------------------------------------
		Derived Data and Functions
	-------------------------------------*/

  const accountFormatter = (value, row) => {
    if (!value) {
      return "N/A";
    }
    return (
      <>
        {value}
        {row?.user_email && <div>{row.user_email}</div>}
      </>
    );
  };

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "account_name",
      headerName: "Account",
      formatter: accountFormatter,
    },
    { field: "log_level", headerName: "Type" },
    { field: "message", headerName: "Topic" },
    {
      field: "created_at",
      headerName: "Date",
      formatter: (value) => new Date(value).toLocaleString(),
    },
  ];

  /*-------------------------------------
    	Utility Functions
  -------------------------------------*/

  const closeDataDrawer = () => {
    setDrawerOpen(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  /*-------------------------------------
		Event Handlers
	-------------------------------------*/

  const handleClickReadLog = (log = {}) => {
    setLog(log);
    setDrawerOpen(true);
  };

  /*-------------------------------------
		Effects
	-------------------------------------*/

  useEffect(() => {
    document.title = "Manage Logs";
  }, []);

  return (
    <>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "600px",
          },
        }}
      >
        <LogDetails log={log} closeDataDrawer={closeDataDrawer} />
      </Drawer>
      <Box className="flex flex-auto flex-col w-full relative" sx={{ p: 3 }}>
        <Box
          className="flex flex-auto flex-col w-full relative"
          sx={{
            backgroundColor: "#fff",
            borderRadius: 2,
            p: 3,
            boxShadow: 1,
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <h1 className="text-4xl font-extrabold leading-none tracking-tight">
                System Logs
              </h1>
              <div className="subtitle">Review &amp; Manage System Logs</div>
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <DataTable
              url={`admin/get_logs${aid ? `?aid=${aid}` : ""}`}
              columns={columns}
              paginationType="server"
              initialPageSize={10}
              searchEnabled={true}
              rowClickAction={handleClickReadLog}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
