import { fetcher } from 'itty-fetcher';
import jwtDecode from 'jwt-decode';
import { log } from './logging';
const STORE = "_tomra_auth";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
console.log('Environment Variables:', {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  NODE_ENV: process.env.NODE_ENV,
  // Add other relevant variables here
});
export const req = async (method, url, data = {}) => {
  console.log("base URL", API_BASE_URL);
  try {
    // Get the current token and refresh token from local storage
    const auth_data = localStorage.getItem(STORE);
    let token = '';
    let refreshToken = '';
    // Refresh token if necessary
    if (auth_data) {
      const { token: parsedToken, refreshToken: parsedRefreshToken } = JSON.parse(auth_data);
      token = parsedToken;
      refreshToken = parsedRefreshToken;
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime + 60 * 5) {
        await refreshAuthToken(refreshToken);
      }
    }

    // Configuration options for the fetcher
    let options = {
      base: API_BASE_URL,
      // Handle non-200 status codes
      handleResponse: async (response) => {
        if (!response.ok) {
          const errorBody = await response.json(); // Capture the error response body
          throw { status: response.status, error: errorBody };
        }
        return response.json(); // For 200 responses, return JSON data
      },
      // Add token to request headers
      transformRequest(req) {
        req.headers['Authorization'] = `Bearer ${token}`;
        return req;
      },
    };

    // If the URL is absolute, remove the base option
    if (url?.includes("http")) {
      delete options.base;
    }

    // Initialize the fetcher with the configured options
    let f = new fetcher(options);
    method = method.toUpperCase();

    // If data is provided, create a FormData object
    if (data) {
      const formData = new FormData();
      for (let key in data) {
        // If data is an object, stringify it
        if (typeof data[key] === 'object') {
          data[key] = JSON.stringify(data[key]);
        }
        formData.append(key, data[key]);
      }

      // Send the request with data
      try {
        return await f[method](url, formData);
      } catch (e) {
        //console.log("@@@@@@@@Request failed", e);
        if (e.error) {
          //console.error(`Error: ${e.status}`, e.error);
          return e.error;
        } else {
          return false;
        }
      }
    } else {
      // Send the request without data
      return await f[method](url);
    }
  } catch (e) {
    console.error("@@@@@@@@Request failed", e);
    if (e.error) {
      return e.error;
    } else {
      return e;
    }
  }
};







// Function to refresh auth token
const refreshAuthToken = async (refreshToken) => {
  try {
    const refresh_url = `${API_BASE_URL}refresh_token`;
    const response = await fetch(refresh_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refreshToken }),
    });
    const result = await response.json();

    if (result.success) {
      localStorage.setItem('token', result.token);
      localStorage.setItem('refreshToken', result.refreshToken);
    } else {
      throw new Error('Failed to refresh token');
    }
  } catch (error) {
    console.error('Failed to refresh token', error);
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    throw error;
  }
};

// Set an interval to retry failed requests every 30 seconds
//setInterval(retryFailedRequests, 30000); // Retry every 30 seconds
export const upload = async (url, file) => {
  //if url is not full url, add the base url
  if (!url.includes("http")) {
    url = `${API_BASE_URL}${url}`;
  }
  if (!navigator.onLine) {
    return Promise.reject({
      message: "Network connection lost. Please check your internet connection and try again.",
      url,
      response: "Network error: No connection",
    });
  }

  let xhr = new XMLHttpRequest();
  xhr.open("POST", url, true);

  // Set Authorization header
  const auth_data = localStorage.getItem(STORE);
  let token = '';
  if (auth_data) {
    const { token: parsedToken, refreshToken } = JSON.parse(auth_data);
    token = parsedToken;
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    if (decodedToken.exp < currentTime + 60 * 5) {
      token = await refreshAuthToken(refreshToken);
    }
  }
  xhr.setRequestHeader('Authorization', `Bearer ${token}`);

  xhr.withCredentials = false;

  return new Promise((resolve, reject) => {
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        try {
          const response = JSON.parse(xhr.responseText);
          resolve(response);
        } catch (e) {
          reject({
            message: `Error parsing JSON from ${url}: ${e.toString()}, response was: ${xhr.responseText}`,
            url,
            response: xhr.responseText,
          });
        }
      } else {
        reject({
          message: `HTTP Error: ${xhr.status} from ${url}, response was: ${xhr.responseText}`,
          url,
          response: xhr.responseText,
        });
      }
    };

    xhr.onerror = () => {
      reject({
        message: `Network error: ${xhr.statusText} from ${url}`,
        url,
        response: "Network error",
      });
    };

    xhr.ontimeout = () => {
      reject({
        message: `Request timed out for ${url}`,
        url,
        response: "Timeout error",
      });
    };

    const formData = new FormData();
    formData.append('file', file);
    xhr.send(formData);
  });
};