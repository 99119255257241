// 1. React Imports:
import { useCallback, useEffect, useState } from "react";

// 2. Third-Party Library Imports:
import {
  Box,
  Button,
  Chip,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  Select,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format, subDays } from "date-fns";
import { DateRange } from "react-date-range";

// 3. Custom Hooks:
import { useAuth } from "contexts/AuthContext";

// 4. Component Imports:
import { ContainersTable } from "../../components/analytics/ContainersTable";
import { DepositDailyBarChart } from "../../components/analytics/DepositDailyBarChart";
import { EnvironmentalStats } from "../../components/analytics/EnvironmentalStats";
import { FilterReports } from "../../components/analytics/FilterReports";
import { GoogleMapsLoader } from "../../components/analytics/GoogleMapsLoader";
import { MaterialPieChart } from "../../components/analytics/MaterialPieChart";
import { StatsMap } from "../../components/analytics/StatsMap";

// 6. Utility Functions or Constants:
import { req } from "utils/api";

// 7. Assets:
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import logoUrl from "../../assets/images/Tomra_sh_logo_white.svg";

// --------------------------------------------------------------

export function CustomerHome() {
  /*-------------------------------------
		State Variables and Hooks
	-------------------------------------*/
  const { user } = useAuth();
  const role = user?.accountType;

  // Data
  const [propertyOptions, setPropertyOptions] = useState([]);
  const [filterCount, setFilterCount] = useState(0);
  const [filterReports, setFilterReports] = useState({
    propertyFilter: [],
    customerFilter: [],
  });
  const [materialStats, setMaterialStats] = useState([]);
  const [totalUnits, setTotalUnits] = useState(0);

  /* Date Picker */
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState(
    subDays(subDays(new Date(), 7), 30)
  );
  const [endDate, setEndDate] = useState(subDays(new Date(), 7));
  const open = Boolean(anchorEl);
  const dateRangePopoverId = open ? "date-range-popover" : undefined;
  const maxDate = subDays(new Date(), 7);
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  /* Drawer */
  const [drawerOpen, setDrawerOpen] = useState(false);

  /* Tabs */
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Detects if the screen is a mobile device.

  /*-------------------------------------
		Derived Data and Functions
	-------------------------------------*/

  const calculateTotalUnits = (stats) => {
    try {
      return stats.reduce(
        (total, stat) => total + parseInt(stat.total_units),
        0
      );
    } catch (e) {
      return 0;
    }
  };

  /*--------------------------------------
	Utility Functions
  ---------------------------------------*/

  function TabPanel({ children, value, index, ...other }) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  /*--------------------------------------
	Event Handlers
  ---------------------------------------*/

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleClickPrint = () => {
    alert("Placeholder for the Print PDF.");
    return;
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeDate = (ranges) => {
    const { startDate: newStartDate, endDate: newEndDate } = ranges.selection;
    const adjustedEndDate = newEndDate > maxDate ? maxDate : newEndDate;
    setStartDate(newStartDate);
    setEndDate(adjustedEndDate);
  };

  const handleClickOpenDrawer = () => {
    // setCustomer(customer); // Set the customer to be edited
    setDrawerOpen(true); // Open the drawer
  };

  const handleDeletePropertyFilter = (id) => {
    setFilterReports((prevState) => ({
      ...prevState,
      propertyFilter: prevState.propertyFilter.filter((item) => item !== id),
    }));
  };

  /*-------------------------------------
		Effects
	-------------------------------------*/

  useEffect(() => {
    if (user) {
      fetchProperties();
    }
  }, [user]);

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, filterReports]);

  /*-------------------------------------
		Async Functions
	-------------------------------------*/

  const fetchProperties = async () => {
    try {
      // Fetch properties data from the API
      const data = await req("GET", "has_access_to_properties");

      // Ensure that properties is an array and filter out empty location names, then map to location_name
      const filterProperties = data.filter((property) =>
        property?.location_name?.trim()
      ); // Ensures location_name is not empty or only spaces

      // Update the state with the filtered properties
      setPropertyOptions(filterProperties);
    } catch (error) {
      // Handle errors (e.g., network error, invalid data, etc.)
      console.error("Error fetching properties:", error);
      // Optionally, you can set a state to show an error message to the user
      setPropertyOptions([]); // Clear or set a fallback state on error
    }
  };

  const fetchData = useCallback(async () => {
    // console.log("Fetching Data");

    // Format the start and end dates
    let formattedStart = encodeURIComponent(
      startDate.toISOString().split("T")[0]
    );
    let formattedEnd = encodeURIComponent(endDate.toISOString().split("T")[0]);

    // Base URL
    let url = `customer/get_deposits_by_material_type?startDate=${formattedStart}&endDate=${formattedEnd}`;

    // Check for selected properties in filterReports
    if (
      filterReports.propertyFilter &&
      filterReports.propertyFilter.length > 0
    ) {
      // Extract location_id values and join them into a comma-separated string
      const locationIds = filterReports.propertyFilter.join(",");

      // Add the locationsFilter parameter to the URL
      url += `&locationsFilter=${locationIds}`;
    }

    // Fetch data
    const stats = await req("GET", url);

    // Process stats and update state
    const units = calculateTotalUnits(stats);
    setTotalUnits(units);
    setMaterialStats(stats);
  }, [startDate, endDate, filterReports]);

  // --------------------------------------------------------------

  if (!user) {
    return <div>Loading...</div>;
  }

  // --------------------------------------------------------------

  return (
    <>
      {propertyOptions.length > 1 && (
        <>
          {console.log("Drawer Active")}
          <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            PaperProps={{
              sx: {
                width: "100%",
                maxWidth: "600px",
              },
            }}
          >
            <FilterReports
              closeDrawer={closeDrawer}
              filterReports={filterReports}
              setFilterReports={setFilterReports}
              filterCount={filterCount}
              setFilterCount={setFilterCount}
              propertyOptions={propertyOptions}
            />
          </Drawer>
        </>
      )}
      <div id="home" className="flex flex-auto flex-col relative">
        <div className="flex w-full min-w-0 flex-col sm:absolute sm:inset-0 sm:overflow-hidden">
          <div className="flex-auto relative sm:overflow-hidden sm:h-full">
            <div className="relative h-full overflow-auto">
              <div className="absolute bg-blue-gradient w-full -z-1 h-60"></div>
              <div className="flex flex-auto w-full justify-center p-6 md:p-8 pt-0 md:pt-0">
                <div className="flex w-full flex-auto flex-col max-w-6xl">
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    className="pb-6 md:pb-8"
                  >
                    <Grid item sm={12} md={6} sx={{ order: { md: 2 } }}>
                      <img
                        src={logoUrl}
                        alt="TOMRA Sustainability Hub"
                        className="w-100 max-w-60 mx-auto"
                      />
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      md={6}
                      className="text-white"
                      sx={{ order: { md: 1 } }}
                    >
                      <h1 className="text-5xl font-extrabold leading-none">
                        Welcome {user.accountName || ""}
                      </h1>
                      <p className="mt-2">
                        <strong>Account Number </strong>
                        {user.accountId || ""}
                      </p>
                    </Grid>
                  </Grid>
                  <Box className="flex flex-auto flex-col bg-white w-full relative p-6 md:p-8 lg:p-12 sm:overflow-hidden sm:rounded-lg sm:shadow-lg">
                    <Grid container spacing={2} alignItems="top">
                      <Grid item xs={12} md={6}>
                        <div className="text-2xl font-light">Property:</div>
                        <div className="flex flex-auto flex-row items-center">
                          <div className="text-3xl font-medium leading-none tracking-tight">
                            {propertyOptions && propertyOptions.length > 0
                              ? propertyOptions.length === 1
                                ? propertyOptions[0].location_name
                                : filterReports.propertyFilter.length === 0
                                  ? "All properties"
                                  : propertyOptions.length ===
                                      filterReports.propertyFilter.length
                                    ? "All properties"
                                    : filterReports.propertyFilter.length === 1
                                      ? propertyOptions.find(
                                          (option) =>
                                            option.location_id ===
                                            filterReports.propertyFilter[0]
                                        )?.location_name || "Unknown Location"
                                      : "Multiple selected"
                              : "No properties found"}
                          </div>
                          {propertyOptions && propertyOptions.length > 1 && (
                            <Button
                              variant="outlined"
                              className="mdc-button mat-mdc-unelevated-button mat-primary mat-mdc-button-base ml-2"
                              disableElevation
                              onClick={handleClickOpenDrawer}
                            >
                              Filter Properties ({propertyOptions.length})
                            </Button>
                          )}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        className="mt-5 md:mt-0 border-t md:border-none"
                      >
                        <div className="text-2xl font-light">
                          Reporting period:
                        </div>
                        <div className="flex flex-auto flex-row items-center">
                          <div className="text-3xl font-medium leading-none tracking-tight">
                            {`${format(startDate, "MMM dd, yyyy")} - ${format(endDate, "MMM dd, yyyy")}`}
                          </div>
                          <IconButton
                            className="mdc-button mat-mdc-unelevated-button mat-primary mat-mdc-button-base ml-2"
                            disableElevation
                            onClick={handleOpenPopover}
                            aria-label="change date"
                          >
                            <CalendarTodayIcon fontSize="small" />
                          </IconButton>
                        </div>
                        <Popover
                          id={dateRangePopoverId}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClosePopover}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right", // Align the popover's top-right corner with the anchor
                          }}
                        >
                          <DateRange
                            ranges={[selectionRange]}
                            onChange={handleChangeDate}
                            moveRangeOnFirstSelection={false}
                            maxDate={maxDate}
                          />
                        </Popover>
                        <div className="font-light text-md">
                          * Data is current up to 7 days ago.
                        </div>
                      </Grid>
                    </Grid>
                    {Object.values(filterReports).some(
                      (filterArray) =>
                        filterArray.length >= 2 &&
                        filterArray.length !== propertyOptions.length
                    ) && (
                      <Box className="flex flex-auto flex-col mt-6 md:mt-8 lg:mt-12 pt-6 md:pt-8 lg:pt-12 border-t">
                        <div className="text-2xl font-light mb-2">
                          Selected Properties:
                        </div>
                        <div className="flex flex-auto flex-row">
                          <Stack direction="row" spacing={1}>
                            {filterReports.propertyFilter.map((locationId) => {
                              // Find the location_name corresponding to the current locationId
                              const locationName =
                                propertyOptions.find(
                                  (option) => option.location_id === locationId
                                )?.location_name || "Unknown Location"; // Lookup location_name

                              return (
                                <Chip
                                  key={locationId} // Use locationId as the key since it's unique
                                  label={locationName}
                                  variant="outlined"
                                  className="chip-large"
                                  onDelete={() =>
                                    handleDeletePropertyFilter(locationId)
                                  } // Pass locationId to the delete handler
                                />
                              );
                            })}
                          </Stack>
                        </div>
                      </Box>
                    )}
                  </Box>
                  <Box
                    className="pt-8 pb-6 md:pt-10 md:pb-8"
                    sx={{ width: "100%" }}
                  >
                    <Box
                      style={{ boxShadow: "inset 0 -1px var(--fuse-border)" }}
                    >
                      {isMobile ? (
                        <Select
                          value={tabValue}
                          onChange={(event) =>
                            handleChangeTab(null, event.target.value)
                          }
                          fullWidth
                          displayEmpty
                          variant="outlined"
                        >
                          <MenuItem value={0}>Environmental Impact</MenuItem>
                          <MenuItem value={1}>Site Performance</MenuItem>
                          <MenuItem value={2}>Material Types</MenuItem>
                          <MenuItem value={3}>Daily Stats</MenuItem>
                          <MenuItem value={4}>Charity Donations</MenuItem>
                        </Select>
                      ) : (
                        <Tabs
                          value={tabValue}
                          onChange={handleChangeTab}
                          aria-label="responsive tabs example"
                        >
                          <Tab label="Environmental Impact" {...a11yProps(0)} />
                          <Tab label="Site Performance" {...a11yProps(1)} />
                          <Tab label="Material Types" {...a11yProps(2)} />
                          <Tab label="Daily Stats" {...a11yProps(3)} />
                          <Tab label="Charity Donations" {...a11yProps(4)} />
                          <Tab label="Map" {...a11yProps(5)} />
                        </Tabs>
                      )}
                    </Box>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <TabPanel value={tabValue} index={0}>
                      <EnvironmentalStats totalUnits={totalUnits} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                      <div className="flex flex-auto flex-col w-full">
                        <div className="bg-card flex flex-auto flex-col overflow-hidden rounded-2xl p-6 shadow">
                          <ContainersTable
                            role={role}
                            startDate={startDate}
                            endDate={endDate}
                            filterReports={filterReports}
                          />
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                      <div className="flex flex-auto flex-col w-full">
                        <div className="bg-card flex flex-auto flex-col overflow-hidden rounded-2xl p-6 py-12 shadow">
                          <MaterialPieChart materialStats={materialStats} />
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={tabValue} index={3}>
                      <div className="flex flex-auto flex-col w-full">
                        <div className="bg-card flex flex-auto flex-col overflow-hidden rounded-2xl p-6 shadow">
                          <DepositDailyBarChart materialStats={materialStats} />
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={tabValue} index={4}>
                      <div className="flex h-full flex-auto flex-col items-center justify-center">
                        <div className="mt-12 text-center text-4xl font-extrabold leading-tight tracking-tight sm:mt-24 md:text-7xl md:leading-none">
                          Charity Donations
                        </div>
                        <div className="text-secondary mt-2 text-center text-lg font-medium tracking-tight md:text-xl">
                          Sorry for the inconvenience, Charity Donations should
                          be available soon!
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={tabValue} index={5}>
                      <div className="flex flex-auto flex-col w-full">
                        <div className="bg-card flex flex-auto flex-col overflow-hidden rounded-2xl shadow">
                          <GoogleMapsLoader>
                            <StatsMap
                              role="customer"
                              startDate={startDate}
                              endDate={endDate}
                              filterReports={filterReports}
                            />
                          </GoogleMapsLoader>
                        </div>
                      </div>
                    </TabPanel>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
