import LoginsStats from "components/analytics/loginsStats";

export function AdminDashboard() {
  return (
    <>
      <div className="flex flex-auto flex-col w-full relative">
        <div className="flex flex-auto flex-col min-w-0 w-full dark:bg-transparent sm:absolute sm:inset-0 sm:overflow-hidden">
          <div className="flex flex-auto overflow-hidden">
            <div className="flex flex-auto flex-col overflow-hidden sm:overflow-y-auto p-6">
              <LoginsStats />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
