// 1. React Imports:
import React, { useState, useEffect, useCallback } from "react";

// 2. Third-Party Library Imports:
import { Button, Grid, CircularProgress, Backdrop } from "@mui/material";
import { useToast } from "contexts/ToastContext"; // Import the useToast hook
// 3. Custom Hooks:
import { useDropzone } from "react-dropzone";

// 4. Component Imports:

// 6. Utility Functions or Constants:
import { upload, req } from "utils/api";

// 7. Assets:
import AddIcon from "@mui/icons-material/Add";
import FileIcon from "@mui/icons-material/InsertDriveFile";
import LoaderIcon from "@mui/icons-material/Loop";
import DeleteIcon from "@mui/icons-material/Delete";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export function FileManager() {
  /*--------------------------------------
		  State Variables and Hooks
	-------------------------------------*/
  const { showToast } = useToast(); // Get the showToast function from the context
  const [filesUploading, setFilesUploading] = useState(false);
  const [filesUploaded, setFilesUploaded] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);
  const [processedFiles, setProcessedFiles] = useState(0);
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    fileName: '',
    fileId: null
  });


  /*--------------------------------------
		  Derived Data and Functions
	  -------------------------------------*/

  const filesHtml = filesUploaded.map((file) => (
    <div key={file.id} className="flex items-center justify-between p-4 border-b">
      <div className="flex items-center">
        <div className="flex items-center justify-center w-10 h-10 mr-4 bg-gray-200 rounded-full">
          <FileIcon className="w-6 h-6" />
        </div>
        <div className="flex flex-col">
          <p className="text-lg font-medium">{file.file_name}</p>
          <p className="text-sm text-gray-500">
            Uploaded on {new Date(file.time_received).toLocaleString()}
          </p>
        </div>
      </div>
      <Button
        variant="outlined"
        color="error"
        startIcon={<DeleteIcon />}
        onClick={() => setDeleteDialog({ 
          open: true, 
          fileName: file.file_name,
          fileId: file.id 
        })}
      >
        Delete
      </Button>
    </div>
  ));

  /*--------------------------------------
	  Utility Functions
	 -------------------------------------*/

  const processFolder = async (entry) => {
    const files = [];
    
    async function traverseFileTree(entry) {
      if (entry.isFile) {
        if (entry.name.toLowerCase().endsWith('.csv')) {
          const file = await new Promise((resolve) => entry.file(resolve));
          files.push(file);
        }
      } else if (entry.isDirectory) {
        const dirReader = entry.createReader();
        const entries = await new Promise((resolve) => {
          dirReader.readEntries((entries) => resolve(entries));
        });
        for (const childEntry of entries) {
          await traverseFileTree(childEntry);
        }
      }
    }
    
    await traverseFileTree(entry);
    return files;
  };

  const processZipFile = async (zipFile) => {
    // You'll need to add JSZip to your dependencies
    const JSZip = (await import('jszip')).default;
    const zip = await JSZip.loadAsync(zipFile);
    const csvFiles = [];
    
    await Promise.all(
      Object.keys(zip.files).map(async (filename) => {
        if (filename.toLowerCase().endsWith('.csv')) {
          const content = await zip.files[filename].async('blob');
          const file = new File([content], filename, { type: 'text/csv' });
          csvFiles.push(file);
        }
      })
    );
    
    return csvFiles;
  };

  const ZIP_SIZE_THRESHOLD = 50 * 1024 * 1024; // 50MB threshold (chosen arbitrarily), changes if we upload the zip vs individual files

  const onDrop = useCallback(async (acceptedFiles) => {
    setFilesUploading(true);
    setUploadProgress(0);
    setProcessedFiles(0);
    
    try {
      let csvFiles = [];
      let zipFiles = [];
      let successCount = 0;
      let failureCount = 0;
      
      for (const item of acceptedFiles) {
        console.log('Processing file:', item.name, 'Type:', item.type, 'Size:', item.size); // Debug log
        
        if (item.type === 'text/csv') {
          csvFiles.push(item);
        } else if (item.type === 'application/zip' || 
                   item.type === 'application/x-zip-compressed' ||
                   item.name.toLowerCase().endsWith('.zip')) {
          if (item.size > ZIP_SIZE_THRESHOLD) {
            // Large ZIP files will be uploaded directly
            zipFiles.push(item);
          } else {
            // Small ZIP files will be extracted client-side
            const extractedFiles = await processZipFile(item);
            csvFiles = [...csvFiles, ...extractedFiles];
          }
        } else if (item.type === '') {
          // Folder handling remains the same
          const entry = item.webkitGetAsEntry();
          if (entry) {
            const folderFiles = await processFolder(entry);
            csvFiles = [...csvFiles, ...folderFiles];
          }
        }
      }

      if (csvFiles.length === 0 && zipFiles.length === 0) {
        showToast("No valid files found to upload", "warning");
        setFilesUploading(false);
        return;
      }

      setTotalFiles(csvFiles.length + zipFiles.length);
      
      // Upload large ZIP files first
      for (const zipFile of zipFiles) {
        try {
          await upload("admin/upload_zip", zipFile);
          successCount++;
          setProcessedFiles(prev => prev + 1);
          setUploadProgress(prev => prev + (100 / (csvFiles.length + zipFiles.length)));
        } catch (error) {
          failureCount++;
          console.error(`Error uploading ${zipFile.name}:`, error);
          showToast(`Error uploading ${zipFile.name}`, "error");
        }
      }

      // Then upload individual CSV files in batches
      const batchSize = 5;
      for (let i = 0; i < csvFiles.length; i += batchSize) {
        const batch = csvFiles.slice(i, i + batchSize);
        await Promise.all(
          batch.map(async (file) => {
            try {
              await upload("admin/upload_data", file);
              successCount++;
              setProcessedFiles(prev => prev + 1);
              setUploadProgress((prev) => (prev + (100 / (csvFiles.length + zipFiles.length))));
            } catch (error) {
              failureCount++;
              console.error(`Error uploading ${file.name}:`, error);
              showToast(`Error uploading ${file.name}`, "error");
            }
          })
        );
      }

      // Show final summary message
      const totalFiles = csvFiles.length + zipFiles.length;
      if (successCount > 0 && failureCount > 0) {
        showToast(`Uploaded ${successCount} file${successCount !== 1 ? 's' : ''} successfully, ${failureCount} file${failureCount !== 1 ? 's' : ''} failed`, "warning");
      } else if (successCount > 0) {
        showToast(`Successfully uploaded ${successCount} file${successCount !== 1 ? 's' : ''}`, "success");
      } else if (failureCount > 0) {
        showToast(`Failed to upload ${failureCount} file${failureCount !== 1 ? 's' : ''}`, "error");
      }

      getRecentUploads();
    } catch (error) {
      showToast("Error processing files", "error");
    } finally {
      setFilesUploading(false);
      setUploadProgress(0);
      setProcessedFiles(0);
      setTotalFiles(0);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop,
    noClick: false,
    noKeyboard: false,
    accept: {
      'text/csv': ['.csv'],
      'application/zip': ['.zip'],
      'application/x-zip-compressed': ['.zip'],
      'application/octet-stream': ['.zip'],
      '': ['*'] // Allow folder selection
    }
  });

  /*--------------------------------------
		  Event Handlers
	-------------------------------------*/

  const handleClickAdd = () => {};

  const handleDelete = async () => {
    try {
      const response = await req("post", "admin/delete_file_upload", {
        fileName: deleteDialog.fileName
      });
      
      if (response.status === 200) {
        showToast("File deleted successfully", "success");
        getRecentUploads(); // Refresh the table
      }
    } catch (error) {
      showToast("Error deleting file", "error");
    } finally {
      setDeleteDialog({ open: false, fileName: '', fileId: null });
    }
  };

  /*--------------------------------------
		  Effects
	-------------------------------------*/

  useEffect(() => {
    document.title = "File Manager";
    getRecentUploads();
  }, []);

  /*--------------------------------------
		  Async Functions
  -------------------------------------*/
  const getRecentUploads = async () => {
    //get the recent uploads
    let uploads = await req("get", "admin/get_recent_uploads");
    console.log("RecentUploads", uploads);
    setFilesUploaded(uploads);
  };

  return (
    <div id="files" className="flex flex-auto flex-col w-full relative">
      <div id="" className="flex flex-auto flex-col w-full relative">
        <div className="flex min-w-0 flex-auto flex-col dark:bg-transparent sm:absolute sm:inset-0 sm:overflow-hidden">
          <div className="bg-card flex flex-col items-start border-b p-6 dark:bg-transparent sm:flex-row sm:items-center sm:justify-between sm:py-12 md:px-8">
            <div>
              <h1 className="text-4xl font-extrabold leading-none tracking-tight">
                File Manager
              </h1>
              <div className="subtitle">Upload, Review &amp; Manage Files</div>
            </div>
            <div>
              {filesUploading ? (
                <div className="flex items-center justify-center w-full h-full">
                  <LoaderIcon className="w-6 h-6 animate-spin" />
                </div>
              ) : (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Button
                    variant="contained"
                    className="mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base"
                    startIcon={<AddIcon />}
                    disableElevation
                    onClick={() => handleClickAdd()}
                  >
                    Upload File
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-auto overflow-hidden border-t">
            <div className="flex flex-auto flex-col overflow-hidden sm:mb-18 sm:overflow-y-auto">
              {filesHtml}
            </div>
          </div>
        </div>
      </div>
      
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={filesUploading}
      >
        <div className="flex flex-col items-center">
          <CircularProgress color="inherit" />
          <div className="mt-4 text-white">
            Uploading {processedFiles} of {totalFiles} files ({Math.round(uploadProgress)}%)
          </div>
        </div>
      </Backdrop>
      
      <Dialog
        open={deleteDialog.open}
        onClose={() => setDeleteDialog({ open: false, fileName: '', fileId: null })}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {deleteDialog.fileName} and all its associated deposits?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setDeleteDialog({ open: false, fileName: '', fileId: null })}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleDelete} 
            color="error" 
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
