// 1. React Imports:
import { useEffect, useState } from "react";

// 2. Third-Party Library Imports:
import { CircularProgress, TableCell, TableRow } from "@mui/material";
import DataTable from "../../components/DataTable"; // Adjust the import path as necessary
import { format } from "date-fns";


export function ContainersTable({ startDate, endDate, role, filterReports }) {
  /*-------------------------------------
		State Variables and Hooks
	-------------------------------------*/
  // Base URL
  const baseUrl = role === "admin" ? "admin" : "customer";
  const [url, setUrl] = useState();
  const [loading, setLoading] = useState(true);

  /*-------------------------------------
		Derived Data and Functions
	-------------------------------------*/
  const formattedStart = encodeURIComponent(
    startDate.toISOString().split("T")[0]
  );

  const formattedEnd = encodeURIComponent(endDate.toISOString().split("T")[0]);

  /*-------------------------------------
    	Utility Functions
  -------------------------------------*/
  const getQsFilterLocations = (filterReports) => {
    if (
      filterReports.propertyFilter &&
      filterReports.propertyFilter.length > 0
    ) {
      // Extract location_id values and join them into a comma-separated string
      const locationIds = filterReports.propertyFilter.join(",");

      // Return the final URL with the locationsFilter parameter
      return `&locationsFilter=${locationIds}`;
    }
    return ""; // Return an empty string if the condition is not met
  };

  const locationFormatter = (value, row) => {
    if (value) {
      return value;
    } else {
      return "No location in database, G-ID: " + row.location_id;
    }
  };

  /*-------------------------------------
		Event Handlers
	-------------------------------------*/

  /*-------------------------------------
		Effects
	-------------------------------------*/
  useEffect(() => {
    setUrl(
      `${baseUrl}/get_deposits?startDate=${formattedStart}&endDate=${formattedEnd}${getQsFilterLocations(filterReports)}`
    );
    setLoading(false);
  }, [startDate, endDate, filterReports]);

  /*-------------------------------------
  		Data Table Columns
  	-------------------------------------*/
  const columns = [
    {
      field: "deposit_date",
      headerName: "Date",
      width: 150,
      formatter: (value) => format(new Date(value), "MM/dd/yyyy"),
    },
    {
      field: "location_name",
      headerName: "Location",
      width: 100,
      formatter: locationFormatter,
      cellClassName: "center-align",
    },
    //{ field: 'machine_id', headerName: 'Machine ID', width: 150, cellClassName: 'center-align' },
    {
      field: "material",
      headerName: "Material",
      width: 100,
      cellClassName: "center-align",
    },
    {
      field: "count",
      headerName: "Count",
      width: 100,
      cellClassName: "center-align",
    },
  ];

  return (
    <>
      {loading ? (
        <TableRow>
          <TableCell colSpan={columns.length} align="center">
            <CircularProgress />
          </TableCell>
        </TableRow>
      ) : (
        <DataTable
          url={url}
          columns={columns}
          paginationType="server"
          initialPageSize={10}
          searchEnabled={false} // Disable search if it's not needed
        />
      )}
    </>
  );
}
