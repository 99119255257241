import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { req } from "utils/api";
import { Bar } from "react-chartjs-2";
import { BarElement } from "chart.js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

export default function LoginsStats() {
  const [loginData, setLoginData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    //get last 30 days logins stats start date - 30 days yyyy-mm-dd
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 30);
    const endDate = new Date();
    const startDateString = startDate.toISOString().split("T")[0];
    const endDateString = endDate.toISOString().split("T")[0];
    req(
      "GET",
      `admin/get_logins_stats?startDate=${startDateString}&endDate=${endDateString}`
    ).then((res) => {
      if (!res.data) {
        setLoginData([]);
      } else if (res.data.length > 0) {
        setLoginData(res.data);
        setDateRange(res.dateRange);
        processChartData(res.data);
      }
      setIsLoading(false);
    });
  }, []);

  const processChartData = (data) => {
    const startDate = new Date(data[0].login_time);
    const endDate = new Date(data[data.length - 1].login_time);
    const daysDifference = Math.ceil(
      (endDate - startDate) / (1000 * 60 * 60 * 24)
    );

    let grouping = "daily";
    if (daysDifference > 60) {
      grouping = "weekly";
    }

    const dateRange = [];
    for (
      let d = new Date(startDate);
      d <= endDate;
      d.setDate(d.getDate() + 1)
    ) {
      dateRange.push(d.toISOString().split("T")[0]);
    }

    const loginsByPeriod = {};
    data.forEach((login) => {
      const date = new Date(login.login_time);
      let key;
      if (grouping === "daily") {
        key = date.toISOString().split("T")[0];
      } else {
        // For weekly, use the Monday of each week as the key
        const day = date.getDay();
        const diff = date.getDate() - day + (day === 0 ? -6 : 1);
        key = new Date(date.setDate(diff)).toISOString().split("T")[0];
      }
      loginsByPeriod[key] = (loginsByPeriod[key] || 0) + 1;
    });

    const labels = Object.keys(loginsByPeriod).sort();
    const values = labels.map((label) => loginsByPeriod[label]);

    setChartData({
      labels: labels,
      datasets: [
        {
          label: grouping === "daily" ? "Logins per day" : "Logins per week",
          data: values,
          backgroundColor: "rgba(75, 192, 192, 0.6)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    });
  };

  const getUniqueUsers = () => {
    return new Set(loginData.map((login) => login.user_id)).size;
  };

  const getUniqueAccounts = () => {
    return new Set(loginData.map((login) => login.account_id)).size;
  };

  const NoDataMessage = () => (
    <Box textAlign="center" py={2}>
      <Typography variant="h6">
        No login data available for the period:
      </Typography>
      {dateRange && (
        <Typography variant="body1">
          {new Date(dateRange.startDate).toLocaleDateString()} -{" "}
          {new Date(dateRange.endDate).toLocaleDateString()}
        </Typography>
      )}
    </Box>
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={4}>
        <div className="flex flex-auto flex-col w-full h-full">
          <div className="bg-card flex flex-auto flex-col overflow-hidden rounded-2xl shadow p-6">
            {isLoading ? (
              <CircularProgress style={{ margin: "4rem auto" }} />
            ) : loginData && loginData.length === 0 ? (
              <NoDataMessage />
            ) : (
              <>
                <div className="pb-6">
                  <h1 className="text-4xl font-extrabold leading-none tracking-tight">
                    Daily Logins
                  </h1>
                  <div className="subtitle">Count of user logins by day</div>
                </div>
                {chartData && (
                  <Bar
                    data={chartData}
                    options={{
                      //no legend
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        y: {
                          beginAtZero: true,
                          ticks: {
                            stepSize: 1,
                          },
                        },
                      },
                    }}
                    height={300}
                    style={{ maxHeight: "300px" }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <div className="flex flex-auto flex-col w-full h-full">
          <div className="bg-card flex flex-auto flex-col overflow-hidden rounded-2xl shadow p-6">
            {isLoading ? (
              <CircularProgress style={{ margin: "4rem auto" }} />
            ) : loginData && loginData.length === 0 ? (
              <NoDataMessage />
            ) : (
              <>
                <div className="pb-6">
                  <h1 className="text-4xl font-extrabold leading-none tracking-tight">
                    Login Statistics
                  </h1>
                  <div className="subtitle"></div>
                </div>

                {loginData && (
                  <Grid container spacing={2} textAlign="center">
                    <Grid item xs={4}>
                      <div className="my-4 flex flex-col items-center">
                        <div className="text-7xl font-bold leading-none tracking-tight text-blue-500 sm:text-8xl">
                          {loginData.length}
                        </div>
                        <div className="text-lg font-medium text-gray-500">
                          Total Logins
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="my-4 flex flex-col items-center">
                        <div className="text-7xl font-bold leading-none tracking-tight text-blue-500 sm:text-8xl">
                          {getUniqueUsers()}
                        </div>
                        <div className="text-lg font-medium text-gray-500">
                          Unique Users
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="my-4 flex flex-col items-center">
                        <div className="text-7xl font-bold leading-none tracking-tight text-blue-500 sm:text-8xl">
                          {getUniqueAccounts()}
                        </div>
                        <div className="text-lg font-medium text-gray-500">
                          Unique Accounts
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="mt-4 flex flex-col items-center">
                        <div className="text-lg font-medium text-gray-500">
                          Most Active Account
                        </div>
                        <div className="mt-2 text-5xl font-medium leading-none tracking-tight">
                          {loginData[0].account_name}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <div className="flex flex-auto flex-col w-full h-full">
          <div className="bg-card flex flex-auto flex-col overflow-hidden rounded-2xl shadow p-6">
            {isLoading ? (
              <CircularProgress style={{ margin: "4rem auto" }} />
            ) : loginData && loginData.length === 0 ? (
              <NoDataMessage />
            ) : (
              <>
                <div className="pb-6">
                  <h1 className="text-4xl font-extrabold leading-none tracking-tight">
                    Activity
                  </h1>
                  <div className="subtitle">List of recent user logins</div>
                </div>
                <List className="py-0">
                  {loginData &&
                    loginData.slice(0, 5).map((login, index) => (
                      <React.Fragment key={index}>
                        <ListItem className="py-0">
                          <ListItemText
                            primary={`${new Date(login.login_time).toLocaleString()}`}
                            secondary={`User ID: ${login.user_id}, Account: ${login.account_name}`}
                          />
                        </ListItem>
                        {index < 4 && <Divider />}
                      </React.Fragment>
                    ))}
                </List>
              </>
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
