// 1. React Imports:
import { useEffect, useState } from "react";

// 2. Third-Party Library Imports:
import { Box, Button, Chip, IconButton } from "@mui/material";

// 4. Component Imports:
import { AutocompleteFilter } from "./AutocompleteFilter";

// 6. Utility Functions or Constants:

// 7. Assets:
import CloseIcon from "@mui/icons-material/Close";

// --------------------------------------------------------------

export function FilterReports({
  closeDrawer,
  filterCount,
  setFilterCount,
  filterReports,
  setFilterReports,
  propertyOptions,
}) {
  /*--------------------------------------
    State Variables
  -------------------------------------*/
  const [filters, setFilters] = useState(filterReports);
  const [count, setCount] = useState(filterCount);

  /*--------------------------------------
    Utility Functions
  -------------------------------------*/

  const handleDeleteFilter = (key, option) => {
    setFilters((prev) => ({
      ...prev,
      [key]: prev[key].filter((item) => item !== option),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFilterReports(filters);
    setFilterCount(count);
    closeDrawer();
    // Set the Filter Reports and Close the Drawer
  };

  /*--------------------------------------
	Effects
  ---------------------------------------*/

  useEffect(() => {
    // Calculate the total filter count from all filter arrays
    const totalFilterCount = Object.values(filters).reduce(
      (acc, filter) => acc + filter.length,
      0
    );
    setCount(totalFilterCount);
  }, [filters]);

  /*--------------------------------------
	Async Functions
  ---------------------------------------*/

  return (
    <>
      <div className="relative w-full px-8 sm:px-12">
        <div className="mx-auto flex w-full max-w-3xl items-start justify-between py-6">
          <div>
            <div className="text-4xl font-bold">Filter Reports</div>
          </div>
          <div className="flex items-center mt-1 ml-auto">
            <IconButton onClick={closeDrawer}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="relative flex flex-auto flex-col items-center p-6 pt-0 sm:p-12 sm:pt-0">
        {Object.entries(filters).map(([key, selectedIds]) =>
          selectedIds.length > 0 ? (
            <Box key={key} className="w-full max-w-3xl border-t py-8">
              <div className="text-xl font-bold">Selected Filters</div>
              <Box
                sx={{ display: "flex", gap: 1, flexWrap: "wrap", marginTop: 2 }}
              >
                {selectedIds.map((id) => {
                  const locationName = propertyOptions.find(
                    (option) => option.location_id === id
                  )?.location_name;

                  return (
                    <Chip
                      key={id}
                      label={locationName}
                      variant="outlined"
                      onDelete={() => handleDeleteFilter(key, id)}
                    />
                  );
                })}
              </Box>
            </Box>
          ) : null
        )}
        <div className="w-full max-w-3xl border-t">
          <form noValidate onSubmit={handleSubmit}>
            <div className="mt-8">
              <div className="flex flex-auto flex-col">
                <label
                  htmlFor="customer_name"
                  className="text-xl font-bold mb-2"
                >
                  Property
                </label>
                {/* Property Autocomplete */}
                {/* Property Filter */}
                <AutocompleteFilter
                  options={propertyOptions}
                  placeholder="Enter property name"
                  value={filters.propertyFilter}
                  onChange={(newFilter) =>
                    setFilters((prev) => ({
                      ...prev,
                      propertyFilter: newFilter,
                    }))
                  }
                />
              </div>
            </div>
            <div className="-mx-6 mt-10 flex items-center border-t bg-gray-50 py-4 pl-1 pr-4 dark:bg-transparent sm:-mx-12 sm:pl-7 sm:pr-12">
              <Button
                className="ml-auto mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base"
                onClick={closeDrawer}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="ml-2 mdc-button mat-mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base"
                variant="contained"
                disableElevation
              >
                {`Apply ${count && count !== 0 ? `${count} Filters` : "Filters"}`}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
