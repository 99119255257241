// 1. React Imports:
import { useState } from "react";

// 2. Third-Party Library Imports:
import { Button, Grid, Popover } from "@mui/material";
import { DateRange } from "react-date-range";
import { addDays, format } from "date-fns";

// 4. Component Imports:
import { GoogleMapsLoader } from "../../components/analytics/GoogleMapsLoader";
import { InteractiveMap } from "components/analytics/InteractiveMap";
import { StatsMap } from "components/analytics/StatsMap";

// 7. Assets:
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

export function AdminAnalyticsMaps() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState(addDays(new Date(), -37));
  const [endDate, setEndDate] = useState(addDays(new Date(), -7));
  const [endDateSelected, setEndDateSelected] = useState(true);
  const [selectionRange, setSelectionRange] = useState({
    startDate: null,
    endDate: null,
    key: "selection",
  });
  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? "date-range-popover" : undefined;

  const handleSelect = (ranges) => {
    setEndDateSelected((prevState) => !prevState);
    const { startDate, endDate } = ranges.selection;
    const maxEndDate = addDays(new Date(), -7);

    // If the end date is beyond the maximum, set it to the max end date
    if (endDate > maxEndDate) {
      endDate = maxEndDate;
    }

    // Update the selectionRange state
    setSelectionRange({ startDate, endDate, key: "selection" });

    // Only update the map state if both dates are selected
    setStartDate(new Date(startDate));
    if (!endDateSelected) {
      setEndDate(new Date(endDate));
      setEndDateSelected(true);
      handleClosePopover();
    }
  };

  const handleOpenPopover = (event) => {
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="flex flex-auto flex-col w-full relative">
        <div className="flex flex-auto flex-col min-w-0 w-full dark:bg-transparent sm:absolute sm:inset-0 sm:overflow-hidden">
          <div className="bg-card flex flex-col items-start border-b dark:bg-transparent sm:flex-row sm:items-center sm:justify-between p-6 sm:py-12 md:px-8">
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={6}>
                <h1 className="text-4xl font-extrabold leading-none tracking-tight">
                  Analytics: Interactive Map
                </h1>
                <div className="subtitle">
                  * Data is current up to 7 days ago.
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                className="flex flex-row justify-between md:justify-end"
              >
                <div>
                  <Button
                    className="mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base"
                    startIcon={<CalendarTodayIcon />}
                    disableElevation
                    onClick={handleOpenPopover}
                  >
                    {`${format(startDate, "MMM dd, yyyy")} - ${format(endDate, "MMM dd, yyyy")}`}
                  </Button>
                  <Popover
                    id={popoverId}
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                  >
                    <DateRange
                      ranges={[selectionRange]}
                      onChange={handleSelect}
                      moveRangeOnFirstSelection={false}
                      maxDate={addDays(new Date(), -7)}
                    />
                  </Popover>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="flex flex-auto overflow-hidden">
            <div className="flex flex-auto flex-col overflow-hidden sm:overflow-y-auto p-6">
              <div className="flex flex-auto flex-col w-full">
                <div className="bg-card flex flex-auto flex-col overflow-hidden rounded-2xl shadow">
                  <GoogleMapsLoader>
                    <StatsMap
                      role="admin"
                      startDate={startDate}
                      endDate={endDate}
                    />
                  </GoogleMapsLoader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
