// 1. React Imports:
import { useCallback, useEffect, useState } from "react";

// 2. Third-Party Library Imports:
import {
  Box,
  Button,
  Drawer,
  Grid,
  MenuItem,
  Popover,
  Select,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format, subDays } from "date-fns";
import { DateRange } from "react-date-range";

// 3. Custom Hooks:
import { useAuth } from "../../contexts/AuthContext";

// 4. Component Imports:
import { ContainersTable } from "../../components/analytics/ContainersTable";
import { DepositDailyBarChart } from "../../components/analytics/DepositDailyBarChart";
import { EnvironmentalStats } from "../../components/analytics/EnvironmentalStats";
import { FilterReports } from "../../components/analytics/FilterReports";
import { MaterialPieChart } from "../../components/analytics/MaterialPieChart";

// 6. Utility Functions or Constants:
import { req } from "utils/api";

// 7. Assets:
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FilterListIcon from "@mui/icons-material/FilterList";
import PrintIcon from "@mui/icons-material/Print";

export function AnalyticsReports() {
  /*--------------------------------------
	State Variables and Hooks
  ---------------------------------------*/
  const { user } = useAuth();
  const role = user?.accountType;
  const baseUrl = role === "admin" ? "admin" : "customer";

  /* Data */
  const [filterCount, setFilterCount] = useState(0);
  const [filterReports, setFilterReports] = useState({
    propertyFilter: [],
    customerFilter: [],
  });
  const [propertyOptions, setPropertyOptions] = useState([]);
  const [materialStats, setMaterialStats] = useState([]);
  const [totalUnits, setTotalUnits] = useState(0);

  /* Date Range Picker */
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState(
    subDays(subDays(new Date(), 7), 30)
  );
  const [endDate, setEndDate] = useState(subDays(new Date(), 7));
  const open = Boolean(anchorEl);
  const dateRangePopoverId = open ? "date-range-popover" : undefined;
  const maxDate = subDays(new Date(), 7);
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  /* Drawer */
  const [drawerOpen, setDrawerOpen] = useState(false);

  /* Tabs */
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Detects if the screen is a mobile device.

  /*--------------------------------------
	Derived Data and Functions
  ---------------------------------------*/

  const calculateTotalUnits = (stats) => {
    try {
      return stats.reduce(
        (total, stat) => total + parseInt(stat.total_units),
        0
      );
    } catch (e) {
      return 0;
    }
  };

  /*--------------------------------------
	Utility Functions
  ---------------------------------------*/

  function TabPanel({ children, value, index, ...other }) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  /*--------------------------------------
	Event Handlers
  ---------------------------------------*/

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleClickPrint = () => {
    alert("Placeholder for the Print PDF.");
    return;
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeDate = (ranges) => {
    const { startDate: newStartDate, endDate: newEndDate } = ranges.selection;
    const adjustedEndDate = newEndDate > maxDate ? maxDate : newEndDate;
    setStartDate(newStartDate);
    setEndDate(adjustedEndDate);
  };

  const handleClickOpenDrawer = () => {
    // setCustomer(customer); // Set the customer to be edited
    setDrawerOpen(true); // Open the drawer
  };

  /*--------------------------------------
	Effects
  ---------------------------------------*/

  useEffect(() => {
    fetchProperties();
    document.title = "Analytics Reports";
  }, []);

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, filterReports]);

  /*--------------------------------------
	Async Functions
  ---------------------------------------*/

  const fetchProperties = async () => {
    try {
      //   console.log("Fetching Properties");

      // Fetch properties from the API
      const properties = await req("GET", "has_access_to_properties");

      // Ensure that properties is an array and filter out empty location names, then map to location_name
      const filteredProperties = properties.filter((property) =>
        property?.location_name?.trim()
      ); // Ensures location_name is not empty or only spaces

      // Update the state with the filtered properties
      setPropertyOptions(filteredProperties);

      // If there is only one property set the default filter reports
      if (filteredProperties.length === 1) {
        setFilterReports({
          propertyFilter: [filteredProperties[0].location_id],
          customerFilter: [],
        });
        setFilterCount(1);
      }
    } catch (error) {
      // Handle errors (e.g., network error, invalid data, etc.)
      console.error("Error fetching properties:", error);
      // Optionally, you can set a state to show an error message to the user
      setPropertyOptions([]); // Clear or set a fallback state on error
    }
  };

  const fetchData = useCallback(async () => {
    // console.log("Fetching Data");

    // Format the start and end dates
    let formattedStart = encodeURIComponent(
      startDate.toISOString().split("T")[0]
    );
    let formattedEnd = encodeURIComponent(endDate.toISOString().split("T")[0]);

    // Base URL
    let url = `${baseUrl}/get_deposits_by_material_type?startDate=${formattedStart}&endDate=${formattedEnd}`;

    // Check for selected properties in filterReports
    if (
      filterReports.propertyFilter &&
      filterReports.propertyFilter.length > 0
    ) {
      // Extract location_id values and join them into a comma-separated string
      const locationIds = filterReports.propertyFilter.join(",");

      // Add the locationsFilter parameter to the URL
      url += `&locationsFilter=${locationIds}`;
    }

    // Fetch data
    const stats = await req("GET", url);

    // Process stats and update state
    const units = calculateTotalUnits(stats);
    setTotalUnits(units);
    setMaterialStats(stats);
  }, [baseUrl, startDate, endDate, filterReports]);

  return (
    <>
      {propertyOptions.length > 1 && (
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          PaperProps={{
            sx: {
              width: "100%",
              maxWidth: "600px",
            },
          }}
        >
          <FilterReports
            closeDrawer={closeDrawer}
            filterReports={filterReports}
            setFilterReports={setFilterReports}
            filterCount={filterCount}
            setFilterCount={setFilterCount}
            propertyOptions={propertyOptions}
          />
        </Drawer>
      )}
      <div className="flex flex-auto flex-col w-full relative">
        <div className="flex flex-auto flex-col min-w-0 w-full dark:bg-transparent sm:absolute sm:inset-0 sm:overflow-hidden">
          <div className="bg-card flex flex-col items-start border-b dark:bg-transparent sm:flex-row sm:items-center sm:justify-between p-6 sm:py-12 md:px-8">
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={6}>
                <h1 className="text-4xl font-extrabold leading-none tracking-tight">
                  Analytics: Reports
                </h1>
                <div className="subtitle">
                  * Data is current up to 7 days ago.
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                className="flex flex-row justify-between md:justify-end"
              >
                <div>
                  <Button
                    className="mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base"
                    startIcon={<CalendarTodayIcon />}
                    disableElevation
                    onClick={handleOpenPopover}
                  >
                    {`${format(startDate, "MMM dd, yyyy")} - ${format(endDate, "MMM dd, yyyy")}`}
                  </Button>
                  <Popover
                    id={dateRangePopoverId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                  >
                    <DateRange
                      ranges={[selectionRange]}
                      onChange={handleChangeDate}
                      moveRangeOnFirstSelection={false}
                      maxDate={maxDate}
                    />
                  </Popover>
                </div>
                <div>
                  {propertyOptions.length > 1 && (
                    <Button
                      variant="outlined"
                      className="mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base ml-2"
                      startIcon={<FilterListIcon />}
                      disableElevation
                      onClick={handleClickOpenDrawer}
                    >
                      {filterCount && filterCount > 0
                        ? `${filterCount} Filters`
                        : "Filter"}
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    className="mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base ml-2"
                    startIcon={<PrintIcon />}
                    disableElevation
                    onClick={() => handleClickPrint()}
                  >
                    Print PDF
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="flex flex-auto overflow-hidden">
            <div className="flex flex-auto flex-col overflow-hidden sm:overflow-y-auto p-6">
              <Box className="w-full pb-6 md:pb-8">
                <Box style={{ boxShadow: "inset 0 -1px var(--fuse-border)" }}>
                  {isMobile ? (
                    <Select
                      value={tabValue}
                      onChange={(event) =>
                        handleChangeTab(null, event.target.value)
                      }
                      fullWidth
                      displayEmpty
                      variant="outlined"
                    >
                      <MenuItem value={0}>Environmental Impact</MenuItem>
                      <MenuItem value={1}>Site Performance</MenuItem>
                      <MenuItem value={2}>Material Types</MenuItem>
                      <MenuItem value={3}>Daily Stats</MenuItem>
                      <MenuItem value={4}>Charity Donations</MenuItem>
                    </Select>
                  ) : (
                    <Tabs
                      value={tabValue}
                      onChange={handleChangeTab}
                      aria-label="responsive tabs example"
                    >
                      <Tab label="Environmental Impact" {...a11yProps(0)} />
                      <Tab label="Site Performance" {...a11yProps(1)} />
                      <Tab label="Material Types" {...a11yProps(2)} />
                      <Tab label="Daily Stats" {...a11yProps(3)} />
                      <Tab label="Charity Donations" {...a11yProps(4)} />
                    </Tabs>
                  )}
                </Box>
              </Box>
              <Box classname="w-full">
                <TabPanel value={tabValue} index={0}>
                  <EnvironmentalStats totalUnits={totalUnits} />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <div className="flex flex-auto flex-col w-full">
                    <div className="bg-card flex flex-auto flex-col overflow-hidden rounded-2xl p-6 shadow">
                      <ContainersTable
                        role={role}
                        startDate={startDate}
                        endDate={endDate}
                        filterReports={filterReports}
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  <div className="flex flex-auto flex-col w-full">
                    <div className="bg-card flex flex-auto flex-col overflow-hidden rounded-2xl p-6 py-12 shadow">
                      <MaterialPieChart materialStats={materialStats} />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                  <div className="flex flex-auto flex-col w-full">
                    <div className="bg-card flex flex-auto flex-col overflow-hidden rounded-2xl p-6 shadow">
                      <DepositDailyBarChart materialStats={materialStats} />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={tabValue} index={4}>
                  <div className="flex h-full flex-auto flex-col items-center justify-center">
                    <div className="mt-12 text-center text-4xl font-extrabold leading-tight tracking-tight sm:mt-24 md:text-7xl md:leading-none">
                      Charity Donations
                    </div>
                    <div className="text-secondary mt-2 text-center text-lg font-medium tracking-tight md:text-xl">
                      Sorry for the inconvenience, Charity Donations should be
                      available soon!
                    </div>
                  </div>
                </TabPanel>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
