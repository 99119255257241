// 1. React Imports:
import { useState } from "react";

// 2. Third-Party Library Imports:
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";

// 6. Utility Functions or Constants:
import { req } from "utils/api";

// 7. Assets:
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Email from "@mui/icons-material/Email";
import SubjectIcon from "@mui/icons-material/Subject";

// --------------------------------------------------------------

export function AdminCreateAdmin({
  closeDataDrawer,
  createAdmin,
  userType = "admin",
}) {
  /*--------------------------------------
    State Variables
  -------------------------------------*/
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerDescription, setCustomerDescription] = useState("");
  const [formProcessing, setFormProcessing] = useState(false);
  const [errors, setErrors] = useState({});

  /*--------------------------------------
    Utility Functions
  -------------------------------------*/
  const validateForm = () => {
    let formErrors = {};

    if (!customerName) {
      formErrors.name = "Customer name is required.";
    }
    if (!customerEmail) {
      formErrors.email = "Customer email is required.";
    } else if (!/\S+@\S+\.\S+/.test(customerEmail)) {
      formErrors.email = "Customer email is invalid.";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const data = {
      name: customerName,
      email: customerEmail,
      description: customerDescription,
      accountType: userType,
    };

    try {
      setFormProcessing(true);
      createAdmin(data);
    } catch (error) {
      console.error("Failed to create user:", error.message);
    } finally {
      setFormProcessing(false);
    }
  };

  return (
    <>
      <div className="relative w-full px-8 sm:px-12">
        <div className="mx-auto flex w-full max-w-3xl items-start justify-between py-6">
          <div>
            <div className="text-4xl font-bold">
              Add New {userType === "admin" ? "Administrator" : "Internal User"}
            </div>
          </div>
          <div className="flex items-center mt-1 ml-auto">
            <IconButton onClick={closeDataDrawer}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="relative flex flex-auto flex-col items-center px-6 sm:px-12">
        <div className="w-full max-w-3xl border-t h-full">
          <form
            noValidate
            onSubmit={handleSubmit}
            className="flex flex-auto flex-col h-full"
          >
            <div className="mt-8">
              <div className="flex flex-auto flex-col">
                <label htmlFor="customer_name">
                  {userType === "admin" ? "Admin" : "Internal User"} Name
                </label>
                <TextField
                  id="customer_name"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  error={!!errors.name}
                  helperText={errors.name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  fullWidth
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="flex flex-auto flex-col">
                <label htmlFor="customer_email">
                  {userType === "admin" ? "Admin" : "Internal User"} Email
                </label>
                <TextField
                  id="customer_email"
                  type="email"
                  value={customerEmail}
                  onChange={(e) => setCustomerEmail(e.target.value)}
                  error={!!errors.email}
                  helperText={errors.email}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  fullWidth
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="flex flex-auto flex-col">
                <label htmlFor="customer_description">Notes</label>
                <TextField
                  id="customer_description"
                  value={customerDescription}
                  onChange={(e) => setCustomerDescription(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ display: "flex", alignSelf: "flex-start" }}
                      >
                        <SubjectIcon style={{ alignSelf: "flex-start" }} />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={4}
                />
              </div>
            </div>
            <div className="mt-auto">
              <div className="-mx-6 mt-10 flex items-center border-t bg-gray-50 py-4 pl-1 pr-4 dark:bg-transparent sm:-mx-12 sm:pl-7 sm:pr-12">
                <Button
                  className="ml-auto mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base"
                  onClick={closeDataDrawer}
                  disabled={formProcessing}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="ml-2 mdc-button mat-mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base"
                  variant="contained"
                  disableElevation
                  disabled={formProcessing}
                >
                  {formProcessing
                    ? "Creating..."
                    : `Create ${userType === "admin" ? "Admin" : "Internal User"}`}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
