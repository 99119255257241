import "../assets/css/authStyle.css";
// import logoUrl from '../assets/images/TOMRA_SustainabilityHub_Logo.svg';

import { Outlet } from "react-router-dom";
import { useEffect } from "react";

export function AuthLayout() {
  useEffect(() => {
    // 👇 add class to body element
    document.body.classList.add("auth");
  }, []);

  return (
    <div className="content" style={{ margin: "0 auto" }}>
      <div className="page--card-wrapper">
        {/* <div><img src={logoUrl} alt="Tomra Sustainability Hub" /></div> */}
        <Outlet />
      </div>
    </div>
  );
}
